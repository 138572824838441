import React from 'react';
import PropTypes from 'prop-types';

function Header({ title, subtitle }) {
  return (
    <section className="hero is-large is-primary is-bold">
      <div className="hero-body">
        <div class="container">
          <h1 class="title">
            {title}
          </h1>
          <h2 class="subtitle">
            {subtitle}
          </h2>
        </div>
      </div>
    </section>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

export default Header;

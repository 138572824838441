
import React from 'react';

function Footer({ title, subtitle }) {
  return (
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          Patrick Thomas. 2020.
    </p>
      </div>
    </footer>
  );
}

export default Footer;

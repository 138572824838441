import React from 'react';
import Header from "./Header";
import Footer from "./Footer";

class App extends React.Component {
  state = {
    pageTitle: "Patrick Thomas",
    pageSubtitle: "About me"
  }

  render() {
    return (
      <div>
        <Header title={this.state.pageTitle} subtitle={this.state.pageSubtitle} />

        <section className="section">
          Welcome!
        </section>

        <Footer />
      </div>
    );
  }
}

export default App;
